export default function () {

    const btns = document.querySelectorAll('.js-show-more-posts');

    if ( !btns ) {
        return;
    }

    for ( let btn of btns ) {
        btn.addEventListener('click', (ev) => {
            ev.preventDefault();
            const listWrapper = btn.closest('.w-dyn-list');
            listWrapper.classList.toggle('expanded');
            // const allItems = listWrapper.querySelectorAll('.w-dyn-item');
            // for (let item of allItems) {
            //     item.classList.toggle('expanded');
            // }
        });
    }
}